<template>
  <div :class="containerClass">
    <page-header
      :title="this.$route.params.id ? 'Edit University' : 'Add University'"
      :breadcrumb="breadcrumb"
      :container-class="null"
      class="mb-32pt"
    />

    <page-separator title="University Info" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Basic Uni Info -->

      <b-form-group label-for="small_logo_url" class="row-align-items-center">
        <label class="form-label">
          Small Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Ideal dimensions for small logo should be 512*512. This will be shown as app's favicon.`
            "
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <!-- <user-avatar slot="aside" rounded="lg" size="lg" :isLoading="isLogoUploading" :file-url="owner.logoUrl">
          </user-avatar> -->

          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isSmallLogoUploading">...</span>
            <b-img :src="uni.small_logo_url" class="img-fluid" width="40" alt="Logo" v-else-if="uni.small_logo_url" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'smallLogo')"
            :isUploading="isSmallLogoUploading"
            :isRequired="!uni.small_logo_url"
            placeholder="Select University's Small Logo Picture"
          />
        </b-media>
      </b-form-group>

      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Campus Image
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the image should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <!-- <user-avatar slot="aside" rounded="lg" size="lg" :isLoading="isLogoUploading" :file-url="owner.logoUrl">
          </user-avatar> -->

          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="uni.logoUrl" class="img-fluid" width="40" alt="Logo" v-else-if="uni.logoUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'logo')"
            :isUploading="isLogoUploading"
            :isRequired="!uni.logoUrl"
            placeholder="Select University's Campus Picture"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Name" label-for="name" label-class="form-label">
        <b-form-input id="name" placeholder="Enter University's Name" v-model="uni.name" required />
      </b-form-group>

      <b-form-group label="Contact email" label-for="contact_email" label-class="form-label">
        <b-form-input
          id="contact_email"
          placeholder="Enter University's contact email"
          v-model="uni.contact_email"
          type="email"
        />
      </b-form-group>

      <b-form-group label="Community Link" label-for="community-link" label-class="form-label">
        <b-form-input id="community-link" placeholder="Enter community link" v-model="communityLink" />
      </b-form-group>

      <b-form-group label="Phone" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="uni.phone"
          v-mask="'(###) ### - ####'"
          required
        />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="uni.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="uni.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="uni.city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="uni.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!uni.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            disabled
            v-model="uni.country"
            :reduce="country => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!uni.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <!-- Other Details -->

      <b-form-group label="Short Bio" label-for="short_bio" label-class="form-label">
        <b-form-textarea
          id="short_bio"
          placeholder="Enter University's Short/Summarized Bio."
          :rows="5"
          v-model="uni.short_bio"
        />
      </b-form-group>
      <b-form-group label="Bio" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="uni.bio"
          placeholder="Enter University's Bio"
        />
      </b-form-group>

      <!-- <b-form-group
        :label="$t('schoolMsgs.profileBgImage')"
        label-for="profileBgImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <b-form-file
            id="profileBgImage"
            placeholder="Select University's Profile Background Image"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadFile.profileBgImage"
            @input="uploadImage('profileBgImage')"
            :disabled="isProfileBgImgUploading"
            accept="image/*"
          />
        </b-media>
      </b-form-group> -->

      <b-form-group label="Sponsorships" label-for="scholarships" label-class="form-label">
        <v-select
          id="scholarships"
          class="form-control v-select-custom"
          label="title"
          v-model="uni.scholarships"
          :reduce="sch => sch"
          placeholder="Select Sponsorship"
          :options="allScholarships"
          multiple
          :loading="areSchLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!uni.scholarships" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>

      <div class="row">
        <b-form-group
          :label="$t('schoolMsgs.primaryColor')"
          label-for="primaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="uni.primaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.secondaryColor')"
          label-for="secondaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="uni.secondaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.darkColor')"
          label-for="darkColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="uni.darkColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
      </div>
      <b-form-group :label="$t('schoolMsgs.website')" label-for="website" label-class="form-label">
        <b-form-input id="website" :placeholder="$t('schoolMsgs.websitePlaceholder')" v-model="uni.website" />
      </b-form-group>
      <b-form-group label="Tuition Range" label-for="classTuitionRange" label-class="form-label">
        <div class="row">
          <b-form-group label="From" label-for="tuition_from" label-class="form-label" class="col-md-6">
            <currency-input
              id="tuition_from"
              v-model="uni.tuition_from"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
            />
          </b-form-group>
          <b-form-group label="To" label-for="tuition_to" label-class="form-label" class="col-md-6">
            <currency-input
              id="tuition_to"
              v-model="uni.tuition_to"
              class="form-control"
              placeholder="Enter tuition fee range"
              :allow-negative="false"
              :currency="{ prefix: '$', suffix: '' }"
            />
          </b-form-group>
        </div>
      </b-form-group>

      <b-form-group label="Pass Rate" label-for="passRatio" label-class="form-label">
        <currency-input
          id="passRatio"
          v-model="uni.pass_rate"
          class="form-control"
          placeholder="Enter pass percentage"
          :allow-negative="false"
          :value-range="{ min: 0, max: 100 }"
          :currency="{ prefix: '', suffix: '%' }"
        />
      </b-form-group>

      <b-form-group label="Average Program Weeks" label-for="avgProgramWeeks" label-class="form-label">
        <b-form-input
          id="avgProgramWeeks"
          v-model="uni.avg_prog_weeks"
          class="form-control"
          placeholder="Enter average program weeks"
          type="number"
          :allow-negative="false"
        />
      </b-form-group>
      <b-form-group label="Offerings" label-for="offerings" label-class="form-label">
        <v-select
          id="offerings"
          class="form-control v-select-custom"
          label="text"
          v-model="uni.offerings"
          :reduce="item => item.value"
          placeholder="Select offerings"
          :options="offeringsOptions"
          multiple
        >
        </v-select>

        <b-form-input
          id="other_offerings"
          class="mt-2"
          placeholder="Please specify."
          v-model="uni.otherOfferings"
          required
          v-if="uni.offerings.includes('other')"
        />
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.offeredPrg')" label-for="offered_programs" label-class="form-label">
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="uni.offered_programs"
          :reduce="offered_programs => offered_programs.id"
          placeholder="Select Offered Program"
          :options="allPrograms"
          multiple
          @input="getScholarships()"
          :loading="areProgramsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="University Majors" label-for="university_majors" label-class="form-label">
        <v-select
          id="university_majors"
          class="form-control v-select-custom"
          label="text"
          v-model="uni.majors"
          :reduce="item => item.value"
          placeholder="All University Majors"
          :options="allUniMajors"
          multiple
        >
        </v-select>
      </b-form-group>
      <page-separator title="Programs Offered by University" />

      <div class="card mb-3" v-for="(pg, index) in uni.uni_offered" :key="index">
        <div class="card-header bg-light d-flex">
          <h5 class="my-0">Program {{ index + 1 }}</h5>
          <a
            href="#"
            class="btn btn-danger ml-2 btn-sm ml-auto"
            @click.prevent="removeUniOfferedPg(index)"
            v-if="uni.uni_offered.length > 1"
          >
            <md-icon class="mr-2">close</md-icon>Remove
          </a>
        </div>
        <div class="card-body">
          <b-row class="mx-0">
            <b-form-group label="Program" label-for="pg" label-class="form-label" class="col-md-6">
              <v-select
                :id="`pg-${index}`"
                class="form-control v-select-custom"
                label="title"
                v-model="pg.program"
                :reduce="offered_programs => offered_programs.id"
                placeholder="Select Program"
                :options="allPrograms"
                :loading="areProgramsLoading"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
                <template slot="option" slot-scope="option">
                  <span>{{ option.title }}</span>
                </template>
              </v-select>

              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Link" label-for="link" label-class="form-label" class="col-md-6">
              <b-form-input :id="`link-${index}`" placeholder="Enter Link" v-model="pg.enroll_link" />
            </b-form-group>
          </b-row>

          <b-form-group label="Description" label-for="description" label-class="form-label" class="col-md-12">
            <editor
              :init="{
                height: 400,
                menubar: 'edit view insert format tools table help',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
              }"
              v-model="pg.description"
              placeholder="Enter university's program description"
            />
          </b-form-group>
        </div>
      </div>

      <div class="d-flex justify-content-center mb-4">
        <a class="btn btn-light btn-sm" href="#" @click.prevent="addUniOfferedPg()">
          <md-icon class="mr-2">add</md-icon> Add More
        </a>
      </div>
      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import hexRgb from 'hex-rgb';
import { countries } from 'countries-list';
import { get, map, intersection } from 'lodash';
import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Editor from '@tinymce/tinymce-vue';

import Page from '../../../components/Page.vue';
import {
  PROFILE_VISIBILITY_OPTIONS,
  INSTITUTION_TYPE_OPTIONS,
  CNA_PAYMENT_PLANS,
  SCHOOL_OFFERINGS
} from '../../../common/constants';
import { getClickableLink } from '@/common/utils';
import VSwatches from 'vue-swatches';
// import UserAvatar from '../../../components/User/UserAvatar.vue';
import 'vue-swatches/dist/vue-swatches.css';

var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    VSwatches,
    Editor,
    ImageUploader
  },
  extends: Page,

  data() {
    return {
      PROFILE_VISIBILITY_OPTIONS,
      INSTITUTION_TYPE_OPTIONS,
      CNA_PAYMENT_PLANS,
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      swatches: [
        ['#1FBC9C', '#1CA085', '#2ECC70', '#3398DB'],
        ['#00b3e6', '#8E43AD', '#3D556E', '#222F3D'],
        ['#214e6f', '#F39C19', '#E84B3C', '#C0382B'],
        ['#DDE6E8', '#BDC3C8', '#C0382B', '#868e96']
      ],
      title: this.$route.params.id ? 'Edit University' : 'Add University',

      isPictureLoading: false,
      photo_gallery: [],
      allUniMajors: [
        { value: 'General', text: 'General' },
        { value: 'Agriculture and Environmental Science', text: 'Agriculture and Environmental Science' },
        { value: 'Architecture and Urban Planning', text: 'Architecture and Urban Planning' },
        { value: 'Arts and Humanities', text: 'Arts and Humanities' },
        { value: 'Business and Economics', text: 'Business and Economics' },
        { value: 'Engineering and Technology', text: 'Engineering and Technology' },
        { value: 'Health Sciences', text: 'Health Sciences' },
        { value: 'Social Sciences and Education', text: 'Social Sciences and Education' },
        { value: 'Communication and Media Studies', text: 'Communication and Media Studies' },
        { value: 'Vocational and Technical Training', text: 'Vocational and Technical Training' },
        { value: 'Hospitality and Culinary Arts', text: 'Hospitality and Culinary Arts' },
        { value: 'Law and Public Policy', text: 'Law and Public Policy' },
        { value: 'Interdisciplinary Studies', text: 'Interdisciplinary Studies' }
      ],
      uni: {
        name: '',
        contact_email: '',
        phone: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        subdomain: '',
        country: 'United States',
        logoUrl: null,
        small_logo_url: null,
        profileBgImgUrl: null,
        primaryColor: '#00b3e6',
        secondaryColor: '#868e96',
        darkColor: '#214e6f',
        is_profile_visible: true,
        short_bio: '',
        offerings: [],
        otherOfferings: null,

        offered_programs: [],
        scholarships: [],
        avg_prog_weeks: null,
        tuition_to: null,
        tuition_from: null,
        pass_rate: null,
        website: null,
        uni_offered: [
          {
            program: null,
            enroll_link: '',
            description: ''
          }
        ],
        majors: []
      },

      countryOptions: [],
      stateOptions: [],
      offeringsOptions: SCHOOL_OFFERINGS,

      passErrorCode: null,
      isPassCopied: false,
      prevLength: null,
      isLoading: false,
      isLogoUploading: false,
      isProfileBgImgUploading: false,
      isSmallLogoUploading: false,
      areSchLoading: false,
      allScholarships: [],
      uploadFile: {
        logo: null,
        profileBgImage: null,
        smallLogo: null
      },
      uploadPercent: { logo: 0, profileBgImage: 0, smallLogo: 0 },
      uploadCancelTokenSource: {
        logo: null,
        profileBgImage: null,
        smallLogo: null
      },
      allPrograms: [],
      areProgramsLoading: false,
      communityLink: ''
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Universities', to: { name: 'manage-unis' } },
        {
          text: this.$route.params.id ? 'Edit University' : 'Add University',
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isLogoUploading || this.isProfileBgImgUploading || this.isSmallLogoUploading;
    }
  },
  // validations() {
  //   return {
  //     cnaAccDetails: {
  //       sendgrid_api_key: { required: requiredIf(() => this.cnaAccDetails.sendgrid_from_email) },
  //       sendgrid_from_email: { required: requiredIf(() => this.cnaAccDetails.sendgrid_api_key), email },
  //       paymentPlan: { required: requiredIf(() => this.cnaAcc || this.cnaAccRes) },
  //     },
  //   };
  // },
  methods: {
    ...mapActions('uni', ['getUni', 'createUni', 'updateUni', 'getUniImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('scholarships', ['getAllNewScholarships']),
    get,
    getClickableLink,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },
    addUniOfferedPg() {
      this.uni.uni_offered.push({
        program: '',
        enroll_link: '',
        description: ''
      });
    },
    removeUniOfferedPg(index) {
      this.uni.uni_offered.splice(index, 1);
    },
    async getScholarships() {
      this.areSchLoading = true;
      if (this.uni.offered_programs.length) {
        const scholarships = await this.getAllNewScholarships({ programs: this.uni.offered_programs });
        this.allScholarships = scholarships.data;

        if (this.uni.offered_programs.length < this.prevLength) {
          this.uni.scholarships = this.uni.scholarships.filter(scholarship => {
            return !!intersection(this.uni.offered_programs, scholarship.programs).length;
          });

          this.prevLength = this.uni.offered_programs.length;
        } else {
          this.prevLength = this.uni.offered_programs.length;
        }
      } else if (!this.uni.offered_programs.length) {
        this.allScholarships = [];
        this.uni.scholarships = [];
        this.prevLength = this.uni.offered_programs.length;
      }
      this.areSchLoading = false;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({ ordering: 'id' });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;

      try {
        const data = {
          name: this.uni.name,
          contact_email: this.uni.contact_email,
          phone: this.uni.phone,
          zipcode: this.uni.zipcode,
          address: this.uni.address,
          city: this.uni.city,
          state: this.uni.state,
          country: this.uni.country,
          campus_img_url: this.uni.logoUrl,
          small_logo_url: this.uni.small_logo_url,
          profile_bg_img_url: this.uni.profileBgImgUrl,
          primary_color: this.uni.primaryColor,
          secondary_color: this.uni.secondaryColor,
          dark_color: this.uni.darkColor,
          dark_color_rgb: hexRgb(this.uni.darkColor, { alpha: 0.5 }),
          bio: this.uni.bio,
          short_bio: this.uni.short_bio,
          offered_programs: this.uni.offered_programs,
          offering: this.uni.offerings,
          other_offering: this.uni.otherOfferings,
          majors: this.uni.majors,
          scholarships: this.uni.scholarships.map(scholarship => scholarship.id),
          community_link: this.communityLink ? getClickableLink(this.communityLink) : '',
          uni_offered:
            this.uni.uni_offered.length > 1 ||
            (this.uni.uni_offered.length === 1 &&
              this.uni.uni_offered[0].program &&
              this.uni.uni_offered[0].enroll_link)
              ? this.uni.uni_offered
              : [],
          avg_prog_weeks: this.uni.avg_prog_weeks,
          tuition_to: this.uni.tuition_to,
          tuition_from: this.uni.tuition_from,
          pass_rate: this.uni.pass_rate,
          website: this.uni.website
        };

        if (this.$route.params.id) {
          await this.updateUni({
            id: this.$route.params.id,
            data
          });
          this.makeToast({ variant: 'success', msg: 'University Updated!' });
        } else {
          await this.createUni({
            ...data
          });
          this.makeToast({ variant: 'success', msg: 'University Added!' });
        }
        setTimeout(() => this.$router.push({ name: 'manage-unis' }), 250);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';
      this.isSmallLogoUploading = uploadType === 'smallLogo';
      this.isProfileBgImgUploading = uploadType === 'profileBgImage';
      try {
        const urlResp = await this.getUniImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'logo') {
          this.uni.logoUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'smallLogo') {
          this.uni.small_logo_url = urlResp.upload_url.split('?')[0];
        } else {
          this.uni.profileBgImgUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
      else if (uploadType === 'smallLogo') this.isSmallLogoUploading = false;
      else this.isProfileBgImgUploading = false;
    }
  },

  async mounted() {
    this.isLoading = true;
    // this.fetchScholarships();
    await this.fetchPrograms();
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, state => ({
        value: state.name,
        text: state.name
      }))
    );
    this.countryOptions = this.countryOptions.concat(
      map(countries, country => ({
        value: country.name,
        text: country.name
      }))
    );

    try {
      if (this.$route.params.id) {
        const resp = (await this.getUni(this.$route.params.id)).data;
        this.uni.name = resp.name;
        this.uni.contact_email = resp.contact_email;
        this.uni.phone = resp.phone;
        this.uni.zipcode = resp.zipcode;
        this.uni.address = resp.address;
        this.uni.city = resp.city;
        this.uni.state = resp.state;
        this.uni.country = resp.country;
        this.uni.logoUrl = resp.campus_img_url;
        this.uni.small_logo_url = resp.small_logo_url;
        this.uni.profileBgImgUrl = resp.profile_bg_img_url;
        this.uni.primaryColor = resp.primary_color;
        this.uni.secondaryColor = resp.secondary_color;
        this.uni.darkColor = resp.dark_color;
        this.uni.bio = resp.bio;
        this.uni.short_bio = resp.short_bio;
        this.uni.website = resp.website;
        this.uni.uni_offered = resp.uni_offered.length
          ? resp.uni_offered
          : [
              {
                program: '',
                enroll_link: '',
                description: ''
              }
            ];
        this.uni.majors = resp.majors;
        this.uni.offered_programs = resp.offered_programs;
        this.uni.offerings = resp.offering;
        this.uni.otherOfferings = resp.other_offering;
        this.uni.scholarships = resp.scholarships;
        this.communityLink = resp.community_link;
        this.uni.avg_prog_weeks = resp.avg_prog_weeks;
        this.uni.tuition_to = resp.tuition_to;
        this.uni.tuition_from = resp.tuition_from;
        this.uni.pass_rate = resp.pass_rate;
        if (this.uni.offered_programs.length) {
          this.getScholarships();
        }
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      this.$router.push({ name: 'manage-unis' });
    }

    this.isLoading = false;
  }
};
</script>
