<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />
        <div class="card mb-0">
          <b-table
            :fields="tableColumns"
            :items="connectionRequests"
            :busy="loading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
          >
            <template #cell(uni_name)="data">
              <strong> {{ data.item.university.name }} </strong>
            </template>
            <template #cell(contact_email)="data">
              <strong> {{ data.item.requested_user.email }} </strong>
            </template>
            <template #cell(first_name)="data">
              <strong> {{ data.item.requested_user.first_name }} </strong>
            </template>
            <template #cell(last_name)="data">
              <strong> {{ data.item.requested_user.last_name }} </strong>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { get } from 'lodash';
import { mapActions } from 'vuex';
import Page from '@/components/Page.vue';
import moment from 'moment';
export default {
  components: { PageHeader, PageSeparator },
  extends: Page,

  data() {
    return {
      title: 'Connect Requests',
      loading: true,
      connectionRequests: [],
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Connect Requests', active: true },
      ];
    },

    tableColumns() {
      return [
       
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'contact_email', label: 'Email' },
        { key: 'uni_name', label: 'University Name' },
        { key: 'created_at', label: 'Requested At' , formatter: val => moment(val).format('MM/DD/YYYY, HH:mm:ss')},
      ];
    },
  },

  methods: {
    ...mapActions('student', ['getStudentsConnectionRequests']),
    get,
    async fetchConnectionRequests() {
      try {
        const res = await this.getStudentsConnectionRequests();
        this.connectionRequests = res;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: 'Cannot fetch Lists!' });
      }
      this.loading = false;
    },
  },

  async mounted() {
    this.fetchConnectionRequests();
  },
};
</script>
